<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="true")
		form(@submit.prevent="submit", v-if="advertiser")

			.position-relative
				.buttons.pos-right
					router-link.btn.btn-default(:to="{name:'advertiser-list'}")
						i.la.la-angle-left
						| Back

					button.btn.btn-primary.ml-2(type="submit", :disabled="!!advertiser.reserved") Save
						i.la.la-fw.la-save
				b-tabs(content-class="mt-3")
					b-tab(title="Main")
						.row
							.col-md-6
								.widget
									.widget-header
										h1.title {{ advertiser.id ? 'Edit advertiser' : 'Create advertiser' }}
									.widget-body
										.row
											.col-sm-6(v-if="advertiser.id")
												.form-group
													label ID
													input.form-control(type="text", disabled="disabled", :value="advertiser.id")

											.col-sm-6
												.form-group
													label Status
													div
														toggle-button(v-model="advertiser.status", :labels="{checked: 'Active', unchecked: 'Inactive'}", :width="100", :height="32", :font-size="12")
										.row
											.col-sm-6
												.form-group
													label Name
													input.form-control(type="text", v-model="advertiser.name")
											.col-sm-6
												.form-group
													label Group / Rank
													select.form-control(v-model="advertiser.group_name")
														option(:value="''") none
														option(:value="'A'") A
														option(:value="'B'") B
														option(:value="'C'") C

								.widget
									.widget-header
										h2.title Panel Information
									.widget-body
										.row
											.col-sm-12
												.form-group
													label Panel URL
													input.form-control(type="text", v-model="advertiser.panel_url")
										.row
											.col-sm-6
												.form-group
													label Username
													input.form-control(type="text", v-model="advertiser.panel_username")
											.col-sm-6
												.form-group
													label Password
													input.form-control(type="text", v-model="advertiser.panel_password")


								.widget
									.widget-header
										h2.title Filters
									.widget-body
										.form-group
											label Mask sources
											div
												toggle-button(v-model="advertiser.mask_source", :labels="{checked: 'Active', unchecked: 'Inactive'}", :width="100", :height="32", :font-size="12")
										.row
											.col-sm-6
												.form-group
													label Daily Subsource Limit
													input.form-control(type="text", v-model="advertiser.daily_subsource_limit")
													p.help-block Default number of <strong>daily</strong> unique subsources per campaign. 0 = No limit
											.col-sm-6
												.form-group
													label Total Subsource Limit
													input.form-control(type="text", v-model="advertiser.total_subsource_limit")
													p.help-block Default number of <strong>total</strong> unique subsources per campaign. 0 = No limit
										.row
											.col-sm-6
												.form-group
													label Default offer cap
													input.form-control(type="text", v-model="advertiser.default_offer_cap")
													p.help-block Click cap per offer

								.widget
									.widget-body
										.form-group
											label Comments (Internal use)
											textarea.form-control(rows="3", placeholder="Comments (Internal use)", v-model="advertiser.comments")


							.col-md-6
								.widget
									.widget-header
										h2.title Integration
									.widget-body
										.row
											.col-sm-6
												.form-group
													label Integration Type
													select.form-control(v-model="advertiser.integration_type", @change="selectIntegration()")
														option(:value="null") - Select -
														option(v-for="o in integrationTypeOptions", v-bind:key="o.v", :value="o.v") {{ o.t }}
											.col-sm-6
												.form-group
													label &nbsp;
													div.toggle-wrapper
														toggle-button(v-model="advertiser.fetch_api", :width="40", :height="20", :font-size="14")
														span.lbl Fetch API
										.row
											.col-sm-12
												table.table.table-bordered.table-params
													thead
														tr
															th
																//, @click="addIntegrationParam()"
																//button.btn-circle.btn-primary(type="button", @click="addIntegrationParam()")
																	i.la.la-plus
																span Param
															th Value
															//th &nbsp;
													tbody
														tr(v-for="(p,i) in advertiser.integration_params")
															td {{ p.key }}
															td
																input.form-control(type="text", v-model="p.val", @change="onIntegrationChange()")
															//td
																button.btn.btn-sm(@click="advertiser.integration_params.splice(i,1)")
																	i.la.la-times
												p
													button.btn(type="button", @click="testAPI()", :class="{'btn-success': advertiser.fetch_api_status === 1, 'btn-danger': advertiser.fetch_api_status === 2, 'btn-secondary': advertiser.fetch_api_status === 0}",
														v-b-popover.hover.left="'This action will test the connection settings to validate the integration params'", title="Test Integration") {{ advertiser.fetch_api_status === 1 ? 'Valid Connection' : 'Check Connection' }}
															i.ml-2.la(:class="{'la-check-circle': advertiser.fetch_api_status === 1, 'la-times': advertiser.fetch_api_status === 2, 'la-refresh la-spin': apiBusy }")
										hr.sep
										h2.widget-section-title Tracking Link
										table.table.table-bordered.table-params
											thead
												tr
													th
														button.btn-circle.btn-primary(type="button", @click="addTrackingLinkParam()")
															i.la.la-plus
														span Param
													th Value
													th &nbsp;
											tbody
												tr(v-for="(p, i) in advertiser.tracking_link_params")
													td
														input.form-control(type="text", v-model="p.key")
													td
														input.form-control(type="text", v-model="p.val")
													td
														button.btn.btn-sm(type="button", @click="advertiser.tracking_link_params.splice(i,1)")
															i.la.la-times

								.widget
									.widget-header
										h2.title Integration - Advanced Settings
									.widget-body
										.row
											.col-sm-6
												.form-group
													label &nbsp;
													div.toggle-wrapper
														toggle-button(v-model="advertiser.fetch_api_override_tracking", :width="40", :height="20", :font-size="14")
														span.lbl Override tracking URLs on API update
													div.toggle-wrapper
														toggle-button(v-model="advertiser.fetch_api_override_events", :width="40", :height="20", :font-size="14")
														span.lbl Always override events
													div.toggle-wrapper
														toggle-button(v-model="advertiser.auto_placements", :width="40", :height="20", :font-size="14")
														span.lbl Auto generate campaigns
													div.toggle-wrapper
														toggle-button(v-model="advertiser.api_load_active_only", :width="40", :height="20", :font-size="14")
														span.lbl Load only active offers from API
													// div.toggle-wrapper
														toggle-button(v-model="advertiser.default_event_managed", :width="40", :height="20", :font-size="14")
														span.lbl Default - event managed
													div.toggle-wrapper
														toggle-button(v-model="advertiser.default_event_convert_payout", :width="40", :height="20", :font-size="14")
														span.lbl Default - convert if has payout

											.col-sm-6
												.form-group
													div.toggle-wrapper
														toggle-button(v-model="advertiser.default_require_validation", :width="40", :height="20", :font-size="14")
														span.lbl Require tracking link validation
												.form-group
													label Link validation scan frequency
													select.form-control(v-model="advertiser.scan_frequency")
														option(v-for="o in scanFrequencyOptions", v-bind:key="o.v", :value="o.v") {{ o.t }}
												.form-group
													label Redirect Type
													select.form-control(v-model="advertiser.redirect_type")
														option(v-for="o of redirectTypeOptions", v-bind:key="o.v", :value="o.v") {{ o.t }}


										.row(v-if="USER.special_account_features")
											.col-sm-6
												h4.widget-subtitle Special Features
												div.toggle-wrapper
													toggle-button(v-model="advertiser.default_hide_offers", :width="40", :height="20", :font-size="14")
													span.lbl Automatically hide offers
												//div.toggle-wrapper
													toggle-button(v-model="advertiser.default_enable_smartlink", :width="40", :height="20", :font-size="14")
													span.lbl Auto-Smartlink offers
											.col-sm-6
												.form-group
													label Offer score offset
													input.form-control(type="text", placeholder="100", v-model.number="advertiser.score_offset")


								.widget(v-if="USER.config && USER.config.autoConnectAdv")
									.widget-header
										h2.title Auto-Connect Publishers
										toggle-button(v-model="advertiser.auto_connect_pubs", :labels="{checked: 'Active', unchecked: 'Inactive'}", :width="100", :height="32", :font-size="12")
									.widget-body(v-if="advertiser.auto_connect_pubs")
										.row
											.col-sm-12

												.form-group(style="line-height:21px;")
													b-form-radio-group(v-model="advertiser.auto_connect_pubs_mode", name="auto_connect_pubs_mode")
														b-form-radio(:value="null") Connect All
														b-form-radio(value="blacklist") Blacklist
														b-form-radio(value="whitelist") Whitelist

												.form-group
													label Blacklist
													multiselect(:multiple="true", :options="publisherOptions", v-model="autoConnectBL",
														track-by="v", label="t", deselect-label="", select-label="", :disabled="advertiser.auto_connect_pubs_mode!=='blacklist'")
												.form-group
													label Whitelist
													multiselect(:multiple="true", :options="publisherOptions", v-model="autoConnectWL",
														track-by="v", label="t", deselect-label="", select-label="", :disabled="advertiser.auto_connect_pubs_mode!=='whitelist'")


					b-tab
						template(#title)
							| Postbacks
						.widget
							.widget-header
								h2.title Postback Settings
							.widget-body
								.row
									.col-sm-8
										h4.widget-subtitle Pass these to the advertiser
										//.form-group
											label Install Postback
											b-input-group
												b-form-input(:value="installPostback", readonly="readonly")
												b-input-group-append
													b-button(type="button", v-clipboard:copy="installPostback", v-clipboard:success="onCopySuccess", v-clipboard:error="onCopyError",
														v-b-tooltip.hover.bottom, title="Copy to clipboard")
														i.la.la-copy
										.form-group
											label In-App Event Postback
											b-input-group
												b-form-input(:value="eventPostback", readonly="readonly")
												b-input-group-append
													b-button(type="button", v-clipboard:copy="eventPostback", v-clipboard:success="onCopySuccess", v-clipboard:error="onCopyError",
														v-b-tooltip.hover.bottom, title="Copy to clipboard")
														i.la.la-copy
										.form-group
											label Rejection Postback
											b-input-group
												b-form-input(:value="rejectPostback", readonly="readonly")
												b-input-group-append
													b-button(type="button", v-clipboard:copy="rejectPostback", v-clipboard:success="onCopySuccess", v-clipboard:error="onCopyError",
														v-b-tooltip.hover.bottom, title="Copy to clipboard")
														i.la.la-copy

										.form-group(v-if="advertiser.timezone")
											label Timezone
												i.la.la-info-circle(v-b-tooltip.hover, title="for Advance Privacy postbacks")
											input.form-control(type="text", v-model="advertiser.timezone", readonly="readonly")

										.form-group
											label Custom Install Event Names &nbsp;
												i.la.la-info-circle(v-b-tooltip.hover, title="Events with these names will be considered as install")
											input.form-control(type="text", placeholder="custom_name_1,custom_name_2", v-model="advertiser.custom_install_event_name")
											b-form-checkbox(v-model="advertiser.custom_install_event_name_blank") No name as install &nbsp;
												i.la.la-info-circle(v-b-tooltip.hover, title="Events with no name will be considered as installs")

										h4.widget-subtitle Postbacks Security
										div.toggle-wrapper
											toggle-button(v-model="advertiser.require_secret", :width="40", :height="20", :font-size="14")
											span.lbl Require Secret on conversion/event
										br
										.form-group
											label IP Whitelist
											textarea.form-control(v-model="advertiser.ip_whitelist", rows="5")
										p.clearfix &nbsp;


									.col-sm-4
										table.macros-list.table
											thead
												tr
													th &nbsp;
													th Macro
													th Description
													th Aliases
											tbody
												tr(v-for="m in urlMacros", :class="{'row-selected': m._selected}")
													td.col-select
														a(href="javascript:void(0)", @click="toggleMacro(m)")
															i.la(:class="{'la-check-square':m._selected, 'la-stop':!m._selected}")
													td {{ '{' + m.param + '}' }}
													td {{ m.description }}
													td
														span(v-if="m.aliases  && m.aliases.length")
															span {{ m.aliases.join(', ') }}

					//b-tab(title="Statistics", v-if="advertiser.id")
						.widget
							.widget-body
								report-graph(type="line", :data="graphPerformance", title="General Performance")

					b-tab(title="Offer Defaults", v-if="advertiser.id")
						div.toggle-wrapper
							toggle-button(v-model="offerDefaultsEnabled", :labels="{checked: 'Custom', unchecked: 'Global'}", :width="100", :height="32", :font-size="12")
							span.lbl Custom Offer Defaults
								i.la.la-info-circle.ml-1(v-b-tooltip.hover.right, title="When enabled - new offers of this advertiser will use these settings instead of the Global Offer Defaults")

							p.clearfix
							offer-defaults(v-if="advertiser.offer_defaults", :offer="advertiser.offer_defaults", :updateTimezone="updateOffersTimezone")

					b-tab(title="Activity Log", v-if="advertiser.id")
						.widget
							.widget-body
								activity-log-table(type="advertiser", :id="advertiser.id")


</template>
<script>
import Vue from 'vue';
import moment from 'moment';
const API_STATUS = {
	NEW: 0,
	VALID: 1,
	INVALID: 2,
};
export default {
	name: 'AdvertiserForm',
	async beforeRouteEnter(to, from, next) {
		let publisherOptions = await Vue.ovData.publisher.getOptions(true);
		let integrations = await Vue.ovData.general.getIntegrations();
		let config = await Vue.ovReq.get('settings/getConfig');
		let advertiser = {};

		let macros = [];
		let rejectionMacros = [];
		try {
			let systemMacros = await Vue.ovData.general.getUrlMacros();
			macros = systemMacros.ADVERTISER_POSTBACK_MACROS;
			rejectionMacros = systemMacros.ADVERTISER_REJECTION_POSTBACK_MACROS;
		} catch (e) {
			console.error(e);
		}
		if (to.name === 'advertiser-edit') {
			let entityId = +to.params.id;
			try {
				advertiser = await Vue.ovData.advertiser.get(entityId, true);
			} catch (e) {
				Vue.ovNotify.error('Failed to load advertiser');
				return next(false);
			}
		} else {
			advertiser = Vue.ovData.advertiser.newInstance();
		}
		Vue.ovData.advertiser.separateCustomNames(advertiser);
		Vue.ovData.advertiser._booleans.forEach((f) => {
			advertiser[f] = !!advertiser[f];
		});

		next((vm) => {
			vm.publisherOptions = publisherOptions;
			vm.integrationTypeOptions = integrations;
			vm.advertiser = advertiser;
			vm.pbBaseURL = config && config.paths ? config.paths.postback : '';
			vm.urlMacros = macros.map((m) => ({ ...m, _selected: m.default }));
			vm.rejectionMacros = rejectionMacros;
			if (advertiser.secret) {
				let s = vm.urlMacros.find((m) => m.param === 's');
				s.value = advertiser.secret;
				s = vm.rejectionMacros.find((m) => m.param === 's');
				s.value = advertiser.secret;
			}
			if (advertiser.pb_params) {
				vm.urlMacros.forEach((m) => {
					if (advertiser.pb_params.hasOwnProperty(m.param)) {
						m._selected = advertiser.pb_params[m.param];
					}
				});
			}

			/** auto connect publishers - whitelist/blacklist */
			let publishersHash = {};
			let publishers = [];
			if (advertiser.auto_connect_pubs_blacklist && advertiser.auto_connect_pubs_blacklist.length) {
				advertiser.auto_connect_pubs_blacklist.forEach((p) => {
					publishersHash[p] = true;
				});
				publisherOptions.forEach((o) => {
					if (publishersHash[o.v]) {
						publishers.push(o);
					}
				});
				vm.autoConnectBL = publishers;
			}
			if (advertiser.auto_connect_pubs_whitelist && advertiser.auto_connect_pubs_whitelist.length) {
				advertiser.auto_connect_pubs_whitelist.forEach((p) => {
					publishersHash[p] = true;
				});
				publisherOptions.forEach((o) => {
					if (publishersHash[o.v]) {
						publishers.push(o);
					}
				});
				vm.autoConnectWL = publishers;
			}

			let entityId = +to.params.id;
			if (entityId) {
				vm.graphPerformance = {
					metrics: [
						'revenue',
						'cost',
						'profit',
						'profit_percent',
						'clicks',
						'installs',
						'conversions',
						'reported_conversions',
						'events',
						'reject_events',
					],
					dimensions: [],
					group_timeframe: 'day',
					date_start: moment().subtract(7, 'days').format('YYYY-MM-DD HH:mm'),
					date_end: moment().format('YYYY-MM-DD HH:mm'),
					sort: ['revenue', 'desc'],
					limit: 10,
					filters: {
						advertiser_id: [entityId],
					},
				};
			}

			return vm;
		});
	},
	computed: {
		integration: function () {
			for (let i = 0; i < this.integrationTypeOptions.length; i++) {
				if (this.integrationTypeOptions[i].v === this.advertiser.integration_type) {
					return this.integrationTypeOptions[i];
				}
			}
			return null;
		},
		USER() {
			return this.$store.state.user;
		},

		// installPostback() {
		// 	let params = this.urlMacros.filter(m => m._selected).map(m => `${m.param}=[XXX]`);
		// 	return `${this.pbBaseURL}c/all?${params.join('&')}`;
		// },
		eventPostback() {
			let params = this.urlMacros
				.filter((m) => m._selected)
				.map((m) => `${m.param}=${m.value || '{XXX}'}`);
			return `${this.pbBaseURL}e/all?${params.join('&')}`;
		},
		rejectPostback() {
			let params = this.rejectionMacros.map((m) => `${m.param}=${m.value || '{XXX}'}`);
			return `${this.pbBaseURL}er/all?${params.join('&')}`;
		},
		offerDefaultsEnabled: {
			get() {
				return this.advertiser && !!this.advertiser.offer_defaults;
			},
			set() {
				if (this.advertiser.offer_defaults) {
					Vue.set(this.advertiser, 'offer_defaults', null);
					return;
				}

				const emptyOffer = Vue.ovData.offer.newInstance();
				Vue.set(this.advertiser, 'offer_defaults', emptyOffer);
			},
		},
	},
	data() {
		return {
			busy: false,
			apiBusy: false,
			pbBaseURL: '',
			urlMacros: [],
			rejectionMacros: [],
			// blockedPublishers: [],
			datepickerConf: {
				format: 'DD/MM/YYYY',
			},
			integrationTypeOptions: [],
			advertiser: null,
			scanFrequencyOptions: [
				{ v: 0, t: 'No scan' },
				{ v: -1, t: 'Once' },
				{ v: 60, t: 'Hour' },
				{ v: 120, t: '2 Hours' },
				{ v: 360, t: '6 Hours' },
				{ v: 720, t: '12 Hours' },
				{ v: 1440, t: '24 Hours' },
				{ v: 2880, t: '48 Hours' },
				{ v: 4320, t: '72 Hours' },
			],
			statusOptions: [
				{ v: 0, t: 'Inactive' },
				{ v: 1, t: 'Active' },
			],
			redirectTypeOptions: [
				{ v: null, t: 'N / A' },
				{ v: '200', t: 'HTTP 200 (After page load)' },
				{ v: '302', t: 'HTTP 302 (Default)' },
			],
			graphPerformance: null,
			autoConnectWL: [],
			autoConnectBL: [],
		};
	},
	methods: {
		async updateOffersTimezone() {
			const timezone = this.advertiser.offer_defaults.timezone;
			const advertiserId = this.advertiser.id;
			const data = { timezone, id: advertiserId };
			await this.$ovReq.post('advertiser/updateTimezone', data);
			this.$ovNotify.success('Advertiser offers timezone has been updated');
		},
		selectIntegration() {
			this.advertiser.integration_params = [...this.integration.api_params];
			this.advertiser.tracking_link_params = [...this.integration.tracking_params];
			this.advertiser.fetch_api_status = API_STATUS.NEW;
		},
		onIntegrationChange() {
			this.advertiser.fetch_api_status = API_STATUS.NEW;
		},
		addTrackingLinkParam() {
			this.advertiser.tracking_link_params.push({
				key: '',
				val: '',
			});
		},
		async testAPI() {
			if (this.apiBusy) {
				return;
			}
			this.apiBusy = true;
			let data = {
				integration_type: this.advertiser.integration_type,
				integration_params: this.advertiser.integration_params,
			};
			try {
				await this.$ovReq.post('advertiser/testAPI', data);
				this.$ovNotify.success('Integration is valid');
				this.apiBusy = false;
				this.advertiser.fetch_api_status = API_STATUS.VALID;
			} catch (e) {
				console.error(e);
				this.apiBusy = false;
				this.advertiser.fetch_api_status = API_STATUS.INVALID;
			}
		},
		validateCode() {
			this.advertiser.code = this.advertiser.code.toLowerCase();
			this.advertiser.code = this.advertiser.code.replace(' ', '_');
			this.advertiser.code = this.advertiser.code.replace('-', '_');
			return false;
		},
		async submit() {
			if (this.busy) {
				return;
			}
			if (this.advertiser.reserved) {
				return this.$ovNotify.error('You are not authorized to perform this action');
			}
			this.busy = true;
			let data = Vue.util.extend({}, this.advertiser);
			if (data.custom_install_event_name_blank) {
				data.custom_install_event_name = [
					...data.custom_install_event_name.split(','),
					'_blank_',
				].join(',');
			}
			data.pb_params = this.urlMacros.reduce((hash, m) => {
				hash[m.param] = m._selected;
				return hash;
			}, {});

			data.auto_connect_pubs_blacklist = this.autoConnectBL.map((r) => r.v);
			data.auto_connect_pubs_whitelist = this.autoConnectWL.map((r) => r.v);
			Vue.ovData.advertiser._booleans.forEach((f) => {
				data[f] = data[f] ? 1 : 0;
			});
			try {
				/*let resp = */
				await this.$ovReq.post('advertiser/save', data);
				this.busy = false;
				this.$ovNotify.success('Advertiser has been saved');
				this.$router.push({ name: 'advertiser-list' });
			} catch (e) {
				this.$ovNotify.error(e);
				this.busy = false;
			}
		},

		toggleMacro(macro) {
			Vue.set(macro, '_selected', !macro._selected);
		},

		onCopySuccess() {
			this.$ovNotify.success('Copied to clipboard');
		},

		onCopyError() {
			this.$ovNotify.error('Could not copy text');
		},
	},
};
</script>
<style lang="scss">
.table.macros-list.table {
	.row-selected {
		font-weight: bold;
	}
}
</style>
